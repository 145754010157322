<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from "vue";

const props = defineProps<{
  text: string;
  size?: "default" | "medium" | "large";
}>();

const displayText = ref("");
const typingInterval = ref<NodeJS.Timeout | null>(null);

const typeText = () => {
  if (import.meta.client) {
    let i = 0;
    displayText.value = "";
    if (typingInterval.value) clearInterval(typingInterval.value);
    typingInterval.value = setInterval(() => {
      if (i < props.text.length) {
        displayText.value += props.text.charAt(i);
        i++;
      } else {
        if (typingInterval.value) clearInterval(typingInterval.value);
      }
    }, 100);
  }
};

watch(
  () => props.text,
  () => {
    typeText();
  },
  { immediate: true }
);

onMounted(() => {
  typeText();
});

onUnmounted(() => {
  if (typingInterval.value) clearInterval(typingInterval.value);
});
</script>

<template>
  <div
    :class="[
      'font-bold text-primary',
      size === 'large' ? 'text-4xl sm:text-5xl' : size === 'medium' ? 'text-2xl sm:text-3xl lg:text-4xl' : 'text-xl',
    ]"
  >
    {{ displayText }}
    <span class="animate-blink">|</span>
  </div>
</template>
